const functionalities = {
  actu: '#C2013A',
  home: '#C2013A',
  map: '#C2013A',
  meeting: '#C2013A',
  move: '#C2013A',
  news: '#C2013A',
  phonebook: '#C2013A',
  practicalinformation: '#C2013A',
  register: '#C2013A',
  restaurant: '#C2013A',
  safeAtWork: '#C2013A',
  survey: '#C2013A',
  transport: '#C2013A',
}

const colors: Colors = {
  primary: '#000000',
  secondary: '#93012D',
  accent: '#B30C00',
  primaryDark: '#000000',
  primaryText: '#000000',
  darkText: '#000000',
  secondaryLighter: '#93012D',
  secondaryText: '#B30C00',
  background: '#ffffff',
  contentBackground: '#ffffff',
  disable: '#AAAAAA',
  buttonDisabled: '#9D9CA9',
  urgent: '#FF3030',
  error: '#FF3030',

  darkGrey: '#353A3D',
  iconicGrey: '#565655',
  middleGrey: '#9D9CA9',
  lightGrey: '#EBEBEB',
  white: '#FFFFFF',

  open: '#28BB7D',
  close: '#F02A4E',
  near: '#AD634C',
  homeFeature: '#000000',

  // occupancy
  occupied: '#3D3D3D',
  free: '#00D88D',
  freeOccupied: '#AD634C',
  booked: '#FF003B',
  noInfo: '#9D9CA9',

  // map
  mapItinerary: '#93012D',
  mapDrawer: '#FFFFFF',

  // itinerary
  mobility: '#93012D',
  co2: '#00D88D',
  blablacar: '#FFFFFF',
  blablacarAccent: '#FFFFFF',
  unavailable: '#9D9CA9',

  // register
  full: '#AA2800',
  available: '#009C65',
  transparentGreen: 'rgba(0, 156, 101, 0.10)',
  transparentGrey: 'rgba(0, 0, 0, 0.05)',
  transparentRed: 'rgba(194, 1, 58, 0.10)',
  transparentYellow: 'rgba(227, 165, 53, 0.15)',
  coral: '#FF7A70',
  raspberry: '#C2013A',
  gold: '#F8D581',
  silver: '#F2F2F2',
  copper: '#FBA469',

  // restaurant
  menu: 'rgba(0, 150, 64, 0.05)',

  // quiz
  valid: '#28BB7D',
  validLight: '#e9f8f2',
  // error: '#F02A4E',
  errorLight: '#FAE6EA',

  // misc
  backgroundModal: '#0007',
  blueCarouselArrowOnFocus: '#005faa',

  // Catering
  mauve: '#000000',
  warningBackground: 'rgba(240, 145, 0, 0.15)',
  warning: '#F09100',

  // Home Header
  transparentWhite: 'rgba(255, 255, 255, 0.8)',
  transparentMiddleGrey: 'rgba(155, 155, 155, 0.25)',

  // functionalities
  functionalities,
  lightFunctionalities: functionalities,
}

const theme: Theme = {
  colors: {
    ...colors,
  },
  fonts: {
    h1Bold: {
      fontFamily: 'Loreal Essentielle Bold',
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '30px',
      lineHeight: '41px',
      letterSpacing: '0.5px',
      color: colors.primaryText,
    },
    h1: {
      fontFamily: 'Loreal Essentielle',
      fontStyle: 'normal',
      fontWeight: 300,
      fontSize: '30px',
      lineHeight: '41px',
      letterSpacing: '0.5px',
      color: colors.primaryText,
    },
    h2Bold: {
      fontFamily: 'Loreal Essentielle Bold',
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '24px',
      lineHeight: '27px',
      letterSpacing: '0.3px',
      color: colors.primaryText,
    },
    h2: {
      fontFamily: 'Loreal Essentielle',
      fontStyle: 'normal',
      fontWeight: 300,
      fontSize: '24px',
      lineHeight: '27px',
      color: colors.primaryText,
    },
    h3Bold: {
      fontFamily: 'Loreal Essentielle Bold',
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '18px',
      lineHeight: '22px',
      color: colors.primaryText,
    },
    h3: {
      fontFamily: 'Loreal Essentielle',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '18px',
      lineHeight: '22px',
      color: colors.primaryText,
    },
    bodyBold: {
      fontFamily: 'Loreal Essentielle Bold',
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '16px',
      lineHeight: '19px',
      letterSpacing: '0.2px',
      color: colors.primaryText,
    },
    body: {
      fontFamily: 'Loreal Essentielle',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '19px',
      letterSpacing: '0.2px',
      color: colors.primaryText,
    },
    subtitleBold: {
      fontFamily: 'Loreal Essentielle Bold',
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '14px',
      lineHeight: '19px',
      color: colors.primaryText,
    },
    subtitle: {
      fontFamily: 'Loreal Essentielle',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '19px',
      letterSpacing: '0.5px',
      color: colors.primaryText,
    },
    tagTiroir: {
      fontFamily: 'Georgia Bold',
      fontSize: '14px',
      lineHeight: '16px',
      color: colors.primaryText,
    },
    labelBold: {
      fontFamily: 'Loreal Essentielle Bold',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '12px',
      lineHeight: '16px',
      letterSpacing: '0.1px',
      color: colors.primaryText,
    },
    label: {
      fontFamily: 'Loreal Essentielle',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '16px',
      color: colors.primaryText,
    },
    link: {
      fontFamily: 'Loreal Essentielle',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '15px',
      letterSpacing: '0.3px',
      color: colors.primaryText,
      textDecorationLine: 'underline',
    },
    content: {
      fontFamily: 'Loreal Essentielle',
      fontSize: '20px',
      letterSpacing: '0.2px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '22px',
      color: colors.primaryText,
    },
  },

  constants: {
    menuButtonSize: 60,
  },
}

export default theme
