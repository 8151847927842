import { createEvent, createStore } from 'effector'

export const actions = {
  setRestaurants: createEvent<RestaurantV3[]>('setRestaurants'),
  resetRestaurants: createEvent('resetRestaurants'),
  reset: createEvent('reset'),
}

const initialState: RestaurantState = {
  restaurants: [],
}

export const store = createStore<RestaurantState>(initialState, { name: 'restaurant' })
  .on(actions.setRestaurants, (s, restaurants) => ({ ...s, restaurants }))
  .on(actions.resetRestaurants, (s) => ({ ...s, news: initialState.restaurants }))
  .on(actions.reset, () => ({ ...initialState }))
