import * as React from 'react'
import styled from 'theme/styled-components'

interface Props {
  label: string
  color: string
  backgroundColor: string
  horizontalPadding?: number
}

const Tag = ({ label, color, backgroundColor, horizontalPadding }: Props) => {
  return (
    <Container color={backgroundColor} padding={horizontalPadding}>
      <Label color={color}>{label}</Label>
    </Container>
  )
}

export default Tag

const Container = styled('div')<{
  color: string
  padding: number | undefined
}>`
  background-color: ${(props) => props.color};
  border-radius: 5px;
  padding: 5px ${(props) => (props.padding ? props.padding : 20)}px;
`

const Label = styled('p')<{ color: string }>`
  ${(props) => props.theme.fonts.tagTiroir};
  color: ${(props) => props.color};
`
