import * as React from 'react'
import styled from 'theme/styled-components'
import theme from 'theme/Theme'

import { CategoryIcon, LineIcon } from './svg/TransportIcon'
import Icon from 'components/icons/Icon'
import Tag from 'components/label/Tag'

import useI18n from 'i18n/useI18n'
import useReducer from 'store/useReducer'
import * as TransportStore from './store'
import * as SitesStore from 'site/store'

import { getDistances, getDistancesInMinutes, getLineName } from './utils'

interface Props {
  lineDetails: TransportStation
  index: number
}

const DirectionItem = ({ lineDetails, index }: Props) => {
  const i18n = useI18n()

  const site = useReducer(SitesStore.store, (s) => s.site)
  const favorites = useReducer(TransportStore.store, (s) => s.favorites)

  const setFav = (already: boolean, favoriteLine: TransportStation) => {
    const { name, line, schedules, category } = favoriteLine

    if (already) {
      TransportStore.actions.setFav(
        favorites.filter((f) => f.station !== name || f.line !== line || f.direction !== schedules[0].direction)
      )
    } else if (!!site) {
      const fav = {
        station: name,
        line: line,
        direction: schedules[0].direction,
        category: category,
        siteId: site.id,
      }
      TransportStore.actions.setFav([...favorites, fav])
    }
  }

  const renderTag = (schedule: string, index: number) => {
    return !!schedule ? (
      <TagContainer key={index}>
        {index > 0 && <TagSeparator />}
        <Tag
          label={schedule}
          backgroundColor={theme.colors.background}
          color={theme.colors.primaryText}
          horizontalPadding={10}
        />
      </TagContainer>
    ) : null
  }

  const renderNoSchedule = () => <NoScheduleText>{i18n.t('screens.transport.errors.noSchedule')}</NoScheduleText>

  if (!lineDetails) {
    return null
  }

  const { name, line, category, schedules } = lineDetails
  const direction = !!schedules ? schedules[0].direction : ''

  const schedulesList = getDistances(i18n, new Date(), lineDetails).filter((s) => s !== '')
  const schedulesInMinutes = getDistancesInMinutes(new Date(), lineDetails)

  const isFav = !!favorites.find(
    (f) => f.station === name && f.line === line && f.direction === schedules[0].direction && f.siteId === site?.id
  )

  return (
    <Line
      key={`${category || 'UNKNOWN'} - ${name} - ${direction}`}
      aria-label={i18n.t(`screens.transport.accessibility.hintTransport`, {
        line: i18n.t(`screens.transport.accessibility.lines.${getLineName(line)}`),
        station: name,
        direction: direction,
        schedules:
          schedulesInMinutes[0] !== undefined
            ? `${i18n.t('screens.transport.accessibility.hintNextSchedule', { minutes: schedulesInMinutes[0] })}${
                schedulesInMinutes[1] !== undefined
                  ? i18n.t('screens.transport.accessibility.hintFollowingSchedule', {
                      minutes: schedulesInMinutes[1],
                    })
                  : ''
              }`
            : i18n.t('screens.transport.accessibility.hintNoSchedules'),
      })}>
      {index > 0 && <LineSeparator />}
      <LineNameContainer>
        {!!category && (
          <Logo>
            <CategoryIcon name={category as CategoryTransportIconName} size={24} />
          </Logo>
        )}
        <Logo>
          <LineIcon name={line as LineTransportIconName} size={24} />
        </Logo>
        <DirectionTitle>{direction}</DirectionTitle>
        <FavoriteContainer onClick={() => setFav(isFav, lineDetails)}>
          <Icon name={isFav ? 'heart_filled' : 'heart'} size={22} color={theme.colors.secondary} />
        </FavoriteContainer>
      </LineNameContainer>
      <TagsContainer>{!!schedulesList ? schedulesList.map(renderTag) : renderNoSchedule()}</TagsContainer>
    </Line>
  )
}

export default DirectionItem

// CONTAINERS

const LineNameContainer = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

const Line = styled('div')``

const Logo = styled('div')`
  padding: 2px;
`

const TagsContainer = styled('div')`
  margin-top: -15px;
  margin-left: 5px;
  display: flex;
  flex-direction: row;
`

const TagContainer = styled(TagsContainer)`
  margin: 0;
  padding: 0;
`

const FavoriteContainer = styled('div')`
  padding-top: 2px;
`

// SEPARATORS

const LineSeparator = styled('div')`
  margin: 8px 0px;
  height: 1px;
  background-color: ${(props) => props.theme.colors.lightGrey};
`

const TagSeparator = styled('div')`
  align-self: center;
  margin: 0px 5px;
  height: 13px;
  width: 1px;
  background-color: ${(props) => props.theme.colors.lightGrey};
`

// TEXTS

const DirectionTitle = styled('p')`
  ${(props) => props.theme.fonts.body};
  color: ${(props) => props.theme.colors.primaryDark};
  padding: 0px 5px;
  height: 22px;
  flex: 1;
  max-width: 230px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`

const NoScheduleText = styled('p')`
  ${(props) => props.theme.fonts.tagTiroir};
  color: ${(props) => props.theme.colors.primary};
  padding: 10px 0px 0px 12px;
`
